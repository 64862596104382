import React, {lazy, Suspense} from 'react';
import { createRoot } from 'react-dom/client';

// import reportWebVitals from './reportWebVitals';

import GlobalLayout from "./GlobalLayout.tsx";
import Loader from "factor-lib/Loader.tsx";

import {Loading} from "factor-lib/ILoadable.ts";

// Doit etre en dernier, sinon ne marche pas
import './index.scss';

const MainRouterAndQueryWrapperLazy = lazy(() => import('./MainRouterAndQueryWrapper'));

// Global defered here

createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            <Suspense fallback={
                <GlobalLayout auth={Loading}>
                    <Loader/>
                </GlobalLayout>
            }>
                <MainRouterAndQueryWrapperLazy />
            </Suspense>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
